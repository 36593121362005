<template>
  <div>
    <!-- <el-dialog
          :title="options.title ? options.title : '项目成员评分表'"
          :visible.sync="editShow"
          :close-on-click-modal="false"
          width="70%"
        > -->
    <div class="MonthScoreHeader">
      <div class="leftContent">
        <!-- 评分说明 -->
        <ScoreExplanation />
      </div>  
      <div class="rightContent">
        <div class="item">
          考核月份：
          <el-date-picker
            v-model="queryParams.examineMonth"
            type="month"
            placeholder="选择考核月份"
            value-format="yyyy-MM"
            @change="examineMonthChange"
          >
          </el-date-picker>
        </div>
      </div>
    </div>

    <el-form ref="form" v-loading="loading">
      <el-table :data="scoreList" border>
        <el-table-column type="index" label="序号" width="60" align="center" />
        <el-table-column
          align="center"
          prop="userName"
          label="员工姓名"
          width="120"
        />
        <el-table-column align="center" prop="departmentName" label="部门" />
        <el-table-column
          align="center"
          prop="auditHour"
          label="审核工时"
          width="100"
        />
        <el-table-column align="center" label="工时详情" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="showHourDetail(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="是否已评分" width="100" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.isScore ? "是" : "否" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="评分"
          width="150"
          :show-overflow-tooltip="false"
        >
          <template slot-scope="scope">
            <template v-if="options.mode === 'view'">
              <span>
                <!-- {{ scope.row.isScore ? scope.row.scoreRatio : "" }} -->
                <el-input-number
                  style="width: 115px"
                  disabled
                ></el-input-number>
              </span>
            </template>
            <el-input-number
              v-else
              v-model="scope.row.scoreRatio"
              style="width: 115px"
              :min="0"
              :max="2"
              :precision="2"
              :step="0.01"
              @change="(val) => scoreRatioChange(val, scope.row)"
              :disabled="options.mode === 'view'"
            />
          </template>
        </el-table-column>
        <el-table-column label="评价意见">
          <template slot-scope="scope">
            <div>
              <el-input
                v-model="scope.row.remark"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 3 }"
                :disabled="options.mode === 'view'"
                :required="
                  scope.row.scoreRatio < 0.85 || scope.row.scoreRatio > 1.1
                "
              />
              <p
                style="color: red; font-size: 12px"
                v-if="
                  options.mode !== 'view' &&
                  (scope.row.scoreRatio < 0.85 || scope.row.scoreRatio > 1.1) &&
                  (scope.row.remark === '' || scope.row.remark === null)
                "
              >
                必须填写评价意见
              </p>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <!-- <div slot="footer" class="dialog-footer">
        <el-button
          v-if="options.mode !== 'view'"
          type="primary"
          @click="handleSubmit"
          >提 交</el-button
        >
      </div> -->
    <!-- </el-dialog> -->
    <staff-hour-details
      :is-show="hourDetailShow"
      :detail="detailOptions"
      @closeEdit="hourDetailShow = false"
    />
  </div>
</template>

<script>
export default {
  components: {
    staffHourDetails: () => import("../salary/staffHourDetails.vue"),
    ScoreExplanation: () => import("../salary/ScoreExplanation.vue"),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {
        return {};
      },
    },
    staffList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      scoreList: [],
      loading: false,
      editShow: false,
      hourDetailShow: false,
      detailOptions: {},
      queryParams: {},
    };
  },
  watch: {
    "options.id": {
      immediate: true,
      handler(val) {
        if (val) {
          this.form = this.options.deepClone();
          this.queryParams = {
            scoreType: "project",
            examineMonth: this.getCurrentYearMonth(),
            projectId: val,
          };
          this.scoreList = [];
          this.getstaffManhourDetail();
          this.$nextTick(() => {
            this.$refs.form?.clearValidate();
          });
        }
      },
    },
  },
  methods: {
    examineMonthChange(val) {
      this.queryParams.examineMonth = val;
      this.getstaffManhourDetail();
    },
    getCurrentYearMonth() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth().toString().padStart(2, "0");
      return year + "-" + month;
    },
    showHourDetail(row) {
      this.detailOptions = row;
      this.detailOptions.queryParams = this.queryParams;
      this.hourDetailShow = true;
    },
    getstaffManhourDetail() {
      this.loading = true;
      this.$api.compensationManage
        .staffManhourDetailList(this.queryParams)
        .then((res) => {
          this.scoreList = res.data.records;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    scoreRatioChange(val) {
      if (val >= 0.85 && val <= 1.1) {
        this.form.remark = "";
      }
    },
    formatDate(date, format) {
      if (date) {
        let dateObj = new Date(date);
        let year = dateObj.getFullYear();
        let month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
        let day = dateObj.getDate().toString().padStart(2, "0");

        return format
          .replace("yyyy", year)
          .replace("MM", month)
          .replace("dd", day);
      }
      return date;
    },
    handleSubmit() {
      if (this.scoreList.length == 0) {
        this.$message.warning("当前列表没有需要提交的内容");
        return;
      }

      // 添加评价意见验证
      const invalidScore = this.scoreList.find(
        (item) =>
          (item.scoreRatio < 0.85 || item.scoreRatio > 1.1) &&
          (!item.remark || item.remark.trim() === "")
      );

      if (invalidScore) {
        this.$message.warning("评分小于0.85或大于1.1时，必须填写评价意见");
        return;
      }

      this.loading = true;
      this.$api.compensationManage
        .submitAllScore(this.scoreList)
        .then((res) => {
          this.$message.success("保存成功");
          this.$emit("closeEdit", true);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleCancel() {
      this.$emit("closeEdit");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/config.scss";
.MonthScoreHeader {
  display: flex;
  .rightContent {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #f5f7fa !important;
  color: #c0c4cc !important;
}
</style>
